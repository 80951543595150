import { NgModule } from '@angular/core';

import { KeysPipe } from './keys.pipe';
import { GetByIdPipe } from './getById.pipe';
import { HtmlToPlaintextPipe } from './htmlToPlaintext.pipe';
import { FilterPipe } from './filter.pipe';
import { CamelCaseToDashPipe } from './camelCaseToDash.pipe';
import { GetValueByAttributePipe } from './get-value-by-attribute.pipe';
import {FilterImagePipe} from './filterImage.pipe';
import {ValidCategoryPipe} from "./validCategory.pipe";
import {ValidCategoryOptionPipe} from "./validCategoryOption.pipe";


@NgModule({
    declarations: [
        KeysPipe,
        GetByIdPipe,
        HtmlToPlaintextPipe,
        FilterPipe,
        CamelCaseToDashPipe,
        GetValueByAttributePipe,
        FilterImagePipe,
        ValidCategoryPipe,
        ValidCategoryOptionPipe
    ],
    imports     : [],
    exports     : [
        KeysPipe,
        GetByIdPipe,
        HtmlToPlaintextPipe,
        FilterPipe,
        CamelCaseToDashPipe,
        GetValueByAttributePipe,
        FilterImagePipe,
        ValidCategoryPipe,
        ValidCategoryOptionPipe
    ]
})
export class FusePipesModule
{
}
