export const locale = {
    lang: 'en',
    data: {
        NAV: {
            'APPLICATIONS': 'Applications',
            'SAMPLE'        : {
                TITLE: 'Sample',
                BADGE: '25'
            },
            'DASHBOARD': 'Dashboard',
            'ECOMMERCE': 'E-Commerce',
            'CATEGORY': 'Category',
            'PRODUCTS': 'Products',
            'PRODUCTS-SUB': {
              VIEWPRODUCT: 'View as Product',
                VIEWPRODUCTSKU: 'View as SKU'
            },
            'ORDERS': 'Orders',
            'ORDERS-SUB': {
                SALESORDERS: 'Sales Orders',
                RETURNORDERS: 'Return Orders'
            },
            'CUSTOMERS': 'Customers',
            'SLIDERS': 'Manage Sliders',
            'BANNERS': 'Manage Banners',
            'BOOKINGS': 'Booking',
            'QUOTAS': 'Manage Quotas',
            'CAMPAIGNS': 'Campaign',
            'CAMPAIGNS-SUB': {
                GIFTS: 'Gifts',
                GIFTORDERS: 'Gift Orders'
            },
            'PRICERULES': 'Price Rules',
            'CUSTOMVARIABLES': 'Custom Variables',
            'IMPORTFILE': 'Import File',
            'CONTENTBUILDERS': 'Content Builders',
            'REPORT': 'Report',
            'REPORT-SUB': {
                GENERAL: 'General',
                STAFFREFERRAL: 'Staff Referral'
            }
        }
    }
};
