import { navigationDefault } from "./navigation-default";
import { cm } from "./merchant/cm";
import { scm } from "./merchant/scm";
import { shopffee } from "./merchant/shopffee";
import { jw } from './merchant/jw';

export const getNavigation = () => {
      return [navigationDefault];
};

export const navigation = getNavigation();
