import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import { interval, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
    selector   : 'upload-image-container',
    templateUrl: './upload-image-container.component.html',
    styleUrls    : ['./upload-image-container.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class UploadImageContainerComponent implements OnInit, OnDestroy
{
    // file
    @Input('file')
    file;

    @Input('files')
    files;

    @Output()
    isDelete: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    isSelect: EventEmitter<any> = new EventEmitter<any>();
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     */
    constructor()
    {
        // Set the defaults
        this.file = '';

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    deleteImage(): void{
        // this.files = this.files.filter(e => e !== this.file);
        // console.log(this.file);
        this.isDelete.emit(this.file);
    }

    selectImage(): void{
        this.files.forEach(
            image => {
                image.selected = image.id === this.file.id;
            }
        );
        console.log(this.files);
        this.isSelect.emit(this.files);
    }

}
