import {NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {HttpClientModule, HTTP_INTERCEPTORS} from "@angular/common/http";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {RouterModule, Routes} from "@angular/router";
import {MatMomentDateModule} from "@angular/material-moment-adapter";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {TranslateModule} from "@ngx-translate/core";
import {InMemoryWebApiModule} from "angular-in-memory-web-api";
import "hammerjs";

import {FuseModule} from "@fuse/fuse.module";
import {FuseSharedModule} from "@fuse/shared.module";
import {
    FuseProgressBarModule,
    FuseSidebarModule,
    FuseThemeOptionsModule
} from "@fuse/components";

import {fuseConfig} from "app/fuse-config";

import {AppComponent} from "app/app.component";
import {LayoutModule} from "app/layout/layout.module";
import {CustomHttpInterceptor} from "app/middleware/http.interceptor";
import {EcommerceAppService} from "shared/service/app-service/app.service";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatDialogModule} from "@angular/material/dialog";
import {
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

const appRoutes: Routes = [
    {
        path: "apps",
        loadChildren: () =>
            import("./main/apps/apps.module").then((m) => m.AppsModule)
    },
    {
        path: "auth",
        loadChildren: () =>
            import("./main/pages/authentication/login/login.module").then((m) => m.LoginModule)
    },
    {
        path: "**",
        redirectTo: "auth/login"
    }
];

@NgModule({
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CustomHttpInterceptor,
            multi: true
        },
        EcommerceAppService
    ],
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),

        TranslateModule.forRoot(),
        // InMemoryWebApiModule.forRoot(FakeDbService, {
        //     delay: 0,
        //     passThruUnknownUrl: true
        // }),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatDialogModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,

        // ngx
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxMatNativeDateModule,
        NgxMatSelectSearchModule

    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
