import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpHeaders,
  HttpErrorResponse
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { environment } from "../../environments/environment";
import { EcommerceAppService } from "shared/service/app-service/app.service";
import { catchError, map } from "rxjs/operators";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MessageDialogComponent } from "shared/module/Dialog/message-dialog.component";
import { TranslateService } from '@ngx-translate/core';
import { JwtAuthService } from '../../shared/auth/jwt-auth.service';
import {Router} from "@angular/router";

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  constructor(
    private appService: EcommerceAppService,
    private dialog: MatDialog,
    private _translateService: TranslateService,
    private _jwtAuthService: JwtAuthService,
    private _router: Router
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this._jwtAuthService.getJwtToken();
    const merchantId = this._jwtAuthService.getMerchantId();
    const tokenHeader = token ? { Authorization: `Bearer ${token}` } : {};

    const originalHeaders = {};
    req.headers
      .keys()
      .forEach((key) => (originalHeaders[key] = req.headers.get(key)));


    const headers: HttpHeaders = new HttpHeaders({
      "Merchant-Id": merchantId == null ? environment.merchantId : merchantId,
      "Accept-Language": this._translateService.currentLang === 'en' ? 'en-US' : 'zh-HK',
      ...tokenHeader,
      ...originalHeaders
    });

    const multipartHeaders: HttpHeaders = new HttpHeaders({
      "Merchant-Id": environment.merchantId,
      "Accept-Language": this._translateService.currentLang === 'en' ? 'en-US' : 'zh-HK',
      ...tokenHeader,
      ...originalHeaders
    });

    const url = req.url.startsWith("/") ? req.url : `/${req.url}`;

    const newReq = url.indexOf("import") < 0 ? req.clone({
      url: `${environment.apiUrl}${url}`,
      headers
    }) : req.clone({
      url: `${environment.apiUrl}${url}`,
      headers: multipartHeaders
    });

    this.appService.startLoading();

    return next.handle(newReq).pipe(
      map((e: HttpEvent<any>) => {
        console.log(e);
        if (e.type != 0) {
          this.appService.stopLoading();
        }
        return e;
      }),
      catchError((err: HttpErrorResponse) => {
        this.appService.stopLoading();

        // TODO: pass error msg to show in messageDialog
        // const dialogConfig = new MatDialogConfig();
        // dialogConfig.disableClose = true;
        // dialogConfig.autoFocus = true;

        // this.dialog.open(MessageDialogComponent, dialogConfig);
        if(err.status == 400){
            alert(err.error.error);
        }
        else if (err.status == 401){
            alert('NO permission');
            this._router.navigateByUrl('/');
        }
        else {
        alert("Error occured, please try later");
        }

        return throwError(err);
      })
    );
  }
}
