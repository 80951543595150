import { Pipe, PipeTransform } from '@angular/core';
import { FuseUtils } from '@fuse/utils';

@Pipe({name: 'validCategoryPipe'})
export class ValidCategoryPipe implements PipeTransform
{
    /**
     * Transform
     *
     * @param {any[]} mainArr
     * @returns {any}
     */
    transform(mainArr: any[]): any
    {
        return mainArr.filter(e => e.path.split('/').length >= 3);
    }
}
