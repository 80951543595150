import { NgModule } from '@angular/core';
import {UploadImageContainerComponent} from './upload-image-container.component';
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {CommonModule} from "@angular/common";



@NgModule({
    declarations: [
        UploadImageContainerComponent
    ],
    exports: [
        UploadImageContainerComponent
    ],
    imports: [
        MatIconModule,
        MatButtonModule,
        CommonModule
    ]
})
export class UploadImageContainerModule
{
}
