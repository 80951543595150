import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot
} from "@angular/router";
import { Observable } from "rxjs";

@Injectable()
export class EcommerceAppService implements Resolve<any> {

  isLoading: boolean = false;

  constructor() {}

  public startLoading() {
    this.isLoading = true;
  }

  public stopLoading() {
    this.isLoading = false; 
  }

  public displayErrorMessage(message: string) {
    // TODO implement
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {}
}
