import { Pipe, PipeTransform } from '@angular/core';
import { FuseUtils } from '@fuse/utils';

@Pipe({name: 'validCategoryOptionPipe'})
export class ValidCategoryOptionPipe implements PipeTransform
{
    /**
     * Transform
     *
     * @param {any[]} mainArr
     * @param selectedaCategorys
     * @returns {any}
     */
    transform(mainArr: any[], selectedaCategorys: any[]): any
    {
        mainArr.forEach(e => {
           e.selected = selectedaCategorys.some(selectedaCategory => selectedaCategory.id === e.id);
        });
        return mainArr;
    }
}
