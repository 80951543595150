import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getValueByAttribute',
    pure: false
})
export class GetValueByAttributePipe implements PipeTransform {
    transform(value: any[], attribute: string, property: string): any {
        if(!value) return '';
        const filteredObj = value.find(item => {
            if (item.attribute !== undefined) {
                return item.attribute === attribute;
            }

            return false;
        });

        if (filteredObj) {
            return filteredObj[property];
        }
    }
}