export const navigationDefault = {
    id: "applications",
    title: "Applications",
    translate: "NAV.APPLICATIONS",
    type: "group",
    children: [
        {
            id: "dashboard",
            title: "Dashboard",
            value: "dashboard",
            translate: "NAV.DASHBOARD",
            type: "item",
            icon: "add_photo_alternate",
            url: "/apps/e-commerce/dashboard",
            exactMatch: true
        },
        {
            id: "category",
            title: "Category",
            value: "category",
            translate: "NAV.CATEGORY",
            type: "item",
            icon: "category",
            url: "/apps/e-commerce/categories"
        },
        {
            id: "products",
            title: "Products",
            value: "product",
            translate: "NAV.PRODUCTS",
            type: "collapsable",
            icon: "shopping_cart",
            children: [
                {
                    id: "products",
                    title: "Products",
                    translate: "NAV.PRODUCTS-SUB.VIEWPRODUCT",
                    type: "item",
                    url: "/apps/e-commerce/products",
                    exactMatch: true
                },
                {
                    id: "products",
                    title: "Products",
                    translate: "NAV.PRODUCTS-SUB.VIEWPRODUCTSKU",
                    type: "item",
                    url: "/apps/e-commerce/productSkus",
                    exactMatch: true
                }
            ]
        },
        {
            id: "orders",
            title: "Orders",
            value: "order",
            translate: "NAV.ORDERS",
            type: "collapsable",
            icon: "shopping_cart",
            children: [
                {
                    id: "orders",
                    title: "Orders",
                    translate: "NAV.ORDERS-SUB.SALESORDERS",
                    type: "item",
                    url: "/apps/e-commerce/orders",
                    exactMatch: true
                },
                {
                    id: "orders",
                    title: "Orders",
                    translate: "NAV.ORDERS-SUB.RETURNORDERS",
                    type: "item",
                    url: "/apps/e-commerce/return-orders",
                    exactMatch: true
                }
            ]
        },
        {
            id: "customers",
            title: "Customers",
            value: "customer",
            translate: "NAV.CUSTOMERS",
            type: "item",
            icon: "contacts",
            url: "/apps/e-commerce/customers",
            exactMatch: true
        },
        {
            id: "manage-sliders",
            title: "Manage Sliders",
            value: "slider",
            translate: "NAV.SLIDERS",
            type: "item",
            icon: "add_photo_alternate",
            url: "/apps/e-commerce/manage-sliders",
            exactMatch: true
        },
        {
            id: "manage-banners",
            title: "Manage Banners",
            value: "banner",
            translate: "NAV.BANNERS",
            type: "item",
            icon: "add_photo_alternate",
            url: "/apps/e-commerce/manage-banners",
            exactMatch: true
        },
        {
            id: "manage-bookings",
            title: "Manage Bookings",
            value: "booking",
            translate: "NAV.BOOKINGS",
            type: "item",
            icon: "assignment_turned_in",
            url: "apps/e-commerce/manage-bookings",
            exactMatch: true
        },
        // {
        //     id       : 'manage-quotas',
        //     title    : 'Manage Quotas',
        //     translate: 'NAV.QUOTAS',
        //     type     : 'item',
        //     icon     : 'add_photo_alternate',
        //     url      : 'apps/e-commerce/manage-quotas',
        //     exactMatch: true
        // },
        {
            id: "manage-campaigns",
            title: "Campaigns",
            value: "campaign",
            translate: "NAV.CAMPAIGNS",
            type: "collapsable",
            icon: "sentiment_very_satisfied",
            children: [
                {
                    id: "manage-campaigns",
                    title: "Campaigns",
                    translate: "NAV.CAMPAIGNS",
                    type: "item",
                    url: "apps/e-commerce/manage-campaigns",
                    exactMatch: true
                },
                {
                    id: "manage-campaigns",
                    title: "Campaigns",
                    translate: "NAV.CAMPAIGNS-SUB.GIFTS",
                    type: "item",
                    url: "apps/e-commerce/campaign-gifts",
                    exactMatch: true
                },
                {
                    id: "manage-campaigns",
                    title: "Campaigns",
                    translate: "NAV.CAMPAIGNS-SUB.GIFTORDERS",
                    type: "item",
                    url: "apps/e-commerce/campaign-gift-orders",
                    exactMatch: true
                }
            ]
        },
        {
            id: "price-rules",
            title: "Price Rules",
            value: "rule",
            translate: "NAV.PRICERULES",
            type: "item",
            icon: "sentiment_very_satisfied",
            url: "apps/e-commerce/price-rules",
            exactMatch: true
        },
        {
            id: "custom-variables",
            title: "Custom Variables",
            value: "variable",
            translate: "NAV.CUSTOMVARIABLES",
            type: "item",
            icon: "sentiment_very_satisfied",
            url: "apps/e-commerce/custom-variables",
            exactMatch: true
        },
        {
            id: "import-file",
            title: "Import File",
            value: "import",
            translate: "NAV.IMPORTFILE",
            type: "item",
            icon: "sentiment_very_satisfied",
            url: "apps/e-commerce/import-file",
            exactMatch: true
        },
        {
            id: "content-builder",
            title: "Content Builders",
            value: "post",
            translate: "NAV.CONTENTBUILDERS",
            type: "item",
            icon: "add_photo_alternate",
            url: "/apps/e-commerce/content-builders",
            exactMatch: true
        },
        {
            id: 'report',
            title: 'Report',
            translate: 'NAV.REPORT',
            value: "report",
            type: 'collapsable',
            icon: 'shopping_cart',
            children: [
                {
                    id: 'reportGeneral',
                    title: 'ReportGeneral',
                    translate: 'NAV.REPORT-SUB.GENERAL',
                    type: 'item',
                    url: '/apps/e-commerce/report',
                    exactMatch: true
                },
                {
                    id: 'reportSTAFFREFERRAL',
                    title: 'ReportSTAFFREFERRAL',
                    translate: 'NAV.REPORT-SUB.STAFFREFERRAL',
                    type: 'item',
                    url: '/apps/e-commerce/staff-referral-report',
                    exactMatch: true
                }
            ]
        },
    ]
};
